const en_US = {
  SITE_TITLE: `Site Title`,
  TEA_ID: `TEA identification number`,
  TERM_SCHOOL: `school`,
  BUTTON_VIEW_FEEDER: `Toggle feeder view`,
  BUTTON_VIEW_MAP: `Toggle map view`,
  BUTTON_MENU: `Menu`,
  BUTTON_SEARCH: `Select to search`,
  INPUT_SEARCH: `Enter search criteria`,
  INPUT_PLACEHOLDER_SEARCH: `Search... `,
  BUTTON_TOGGLE_PANEL_FILTERS: `Toggle filters panel`,
  BUTTON_TOGGLE_PANEL_WEIGHT: `Toggle weights panel`,
  BUTTON_TOGGLE_PANEL_INFO: `Toggle info panel`,
  BUTTON_SHARE_TWITTER: `Share on Twitter`,
  DIALOG_SHARE_TWITTER: `All Dallas neighborhoods should have what they need to thrive.`,
  BUTTON_SHARE_FACEBOOK: `Share on Facebook`,
  BUTTON_SHARE_EMAIL: `Share by email`,
  BUTTON_SHARE_LINK: `Copy link`,
  DIALOG_SHARE_EMAIL_SUBJECT: `CPAL Resource Explorer`,
  DIALOG_SHARE_EMAIL_BODY: `All Dallas neighborhoods should have what they need to thrive. Use the CPAL Resource Explorer to see how schools benefit from local resources: `,
  MODAL_SHARE_LINK_HEAD: `Share a Link`,
  MODAL_SHARE_LINK_INSTR: `Copy the link below to share the current view of the Explorer.`,
  MODAL_SHARE_LINK_INPUT: `Current View URL`,
  BTN_CLOSE: `Close`,
  UI_MAP_BUTTON_RESET_FILTER: `Reset active filter`,
  SELECT_VIEW: `Select a view`,
  SELECT_ITEM_MAP: `Map view`,
  SELECT_ITEM_FEEDER: `Feeder view`,
  UI_MAP_SR: "Map of Dallas, with colors showing {metric} for {quintiles}.",
  UI_MAP_RESET: `Reset map`,
  UI_MAP_CAPTURE: `Screenshot map`,
  UI_MAP_CITY: `Dallas`,
  UI_MAP_LEGEND_TITLE: `Active Metric:`,
  UI_MAP_TOGGLE_LAYERS: `TOGGLE LAYERS`,
  UI_MAP_LEGEND_SCHOOL_DOT: `School`,
  UI_MAP_LEGEND_SCHOOL_ZONE: `School zone (2 mile radius)`,
  UI_MAP_LAYERS_REDLINING: `Historical redlining`,
  UI_MAP_LAYERS_DISTRICTS: `District boundaries`,
  // UI_MAP_METRIC_TITLE_CRI: `Weighted Community Resource Index`,
  UI_MAP_METRIC_ABBREV_CRI: `CRI`,
  UI_MAP_METRIC_TITLE_CRI: `Community Resource Index`,
  UI_MAP_METRIC_DESC_CRI: `<p>The Community Resource Index is an average of all Index categories (economics, education, family, health, and community). It presents an overall view of the resources available to each school’s surrounding community.</p>`,
  UI_MAP_METRIC_TITLE_ECON: `Economics`,
  UI_MAP_METRIC_DESC_ECON: `<p><span>Do residents have access to financial services and well-paying jobs?</span><br /><br />Communities with the highest scores on this index are more likely to have higher household incomes, more access to banks and credit unions, lower unemployment rates, and greater access to jobs of all types.</p>`,
  UI_MAP_METRIC_TITLE_EDU: `Education`,
  UI_MAP_METRIC_DESC_EDU: `<p><span>How accessible are ancillary educational services, like quality childcare and afterschool programs, in the community? Have residents completed college?</span><br /><br />Communities with the highest scores on this index have greater access to quality early childhood education, more afterschool program capacity, and a greater share of adults with college degrees.</p>`,
  UI_MAP_METRIC_TITLE_FAM: `Family`,
  UI_MAP_METRIC_DESC_FAM: `<p><span>Are families able to access childcare in the community?</span><br /><br />Communities with the highest scores on this index are more likely to have a greater share of two-parent households (of households with children) and more access to childcare providers.</p>`,
  UI_MAP_METRIC_TITLE_HEAL: `Health`,
  UI_MAP_METRIC_DESC_HEAL: `<p><span>Do residents have access to health insurance, medical services, and fresh fruits and vegetables? Are residents healthy?</span><br /><br />Communities with the highest scores on this index have more access to health clinics and pharmacies and have a greater share of residents with health insurance. Communities with high Health scores also have longer life expectancies and fewer health issues.</p>`,
  UI_MAP_METRIC_TITLE_COMM: `Community`,
  UI_MAP_METRIC_DESC_COMM: `<p><span>Does the community have public amenities? Is the community safe? Are families and businesses secure?</span><br /><br />Communities with the highest scores on this index have more public spaces for community members, fewer cost-burdened households, and fewer residential and business vacancies.</p>`,
  UI_MAP_METRIC_TITLE_ECON_INDEX: `Economics Index`,
  UI_MAP_METRIC_TITLE_EDU_INDEX: `Education Index`,
  UI_MAP_METRIC_TITLE_FAM_INDEX: `Family Index`,
  UI_MAP_METRIC_TITLE_HEAL_INDEX: `Health Index`,
  UI_MAP_METRIC_TITLE_COMM_INDEX: `Community Index`,
  UI_MAP_METRIC_TITLE_COMM_BBP: `Percent of households with access to broadband`,
  UI_MAP_METRIC_COMM_BVP: `Overall business vacancies (%)`,
  UI_MAP_METRIC_COMM_CCTRCAP: `Community and recreation centers, per 10,000 residents`,
  UI_MAP_METRIC_COMM_INCARPCT: `Adults incarcerated (%)`,
  UI_MAP_METRIC_COMM_JUVCRIMECAP: `Juvenile-committed violent crime incidents, per 10,000 residents between ages 16-24`,
  UI_MAP_METRIC_COMM_LIBCAP: `Libraries, per 10,000 residents`,
  UI_MAP_METRIC_COMM_LTBVP: `Long-term business vacancies (24+ months) (%)`,
  UI_MAP_METRIC_COMM_LTRVP: `Long-term residential vacancies (24+ months) (%)`,
  UI_MAP_METRIC_COMM_OCB: `Owner-occupied households that are cost-burdened`,
  UI_MAP_METRIC_COMM_OCBP: `Owner-occupied households that are cost-burdened (%)`,
  UI_MAP_METRIC_COMM_PARKCAP: `Park area (sq mi), per 10,000 residents`,
  UI_MAP_METRIC_COMM_RCB: `Renter occupied households that are cost burdened`,
  UI_MAP_METRIC_COMM_RCBP: `Renter-occupied households that are cost-burdened (%)`,
  UI_MAP_METRIC_COMM_RVP: `Overall residential vacancies (%)`,
  UI_MAP_METRIC_COMM_U18BBP: `Children under 18 with broadband access (%)`,
  UI_MAP_METRIC_COMM_EVRATE: `Number of evictions, per 10,000 residents`,
  UI_MAP_METRIC_ECON_CPR: `Child poverty rate (%)`,
  UI_MAP_METRIC_ECON_FINCAP: `Banks and credit unions, per 10,000 residents`,
  UI_MAP_METRIC_ECON_MEDINC: `Median household income`,
  UI_MAP_METRIC_ECON_PAYDAYCAP: `Payday lending and cash advance businesses, per 10,000 residents`,
  UI_MAP_METRIC_ECON_PCTLWJOBS: `Jobs earning less than $1,500 a month (%)`,
  UI_MAP_METRIC_ECON_PCTMWJOBS: `Jobs earning between $1,500 and $3,333 a month (%)`,
  UI_MAP_METRIC_ECON_TOTJOBS: `Total jobs`,
  UI_MAP_METRIC_ECON_PR: `Poverty rate`,
  UI_MAP_METRIC_ECON_PYR: `Youth (ages 16-24) unemployment rate (%)`,
  UI_MAP_METRIC_ECON_UR: `Unemployment rate`,
  UI_MAP_METRIC_EDU_OOSTKIDS: `Afterschool program seats, per 10,000 children under age 18`,
  UI_MAP_METRIC_EDU_PERBACH: `Residents with a bachelor's degree`,
  UI_MAP_METRIC_EDU_PEREARLYED: `Children ages 3-4 enrolled in school`,
  UI_MAP_METRIC_EDU_QECKIDS: `High-quality, early childcare seats, per 10,000 children between ages 3 and 4`,
  UI_MAP_METRIC_FAM_AFFCCKIDS: `Licensed, affordable childcare centers, per 10,000 children under age 5`,
  UI_MAP_METRIC_FAM_LCCKIDS: `Licensed childcare centers, per 10,000 children under age 5`,
  UI_MAP_METRIC_FAM_TPHH: `Two-parent households`,
  UI_MAP_METRIC_FAM_TPHHPCT: `Two-parent households (%)`,
  UI_MAP_METRIC_HEL_BPHIGH: `Adults with high blood pressure, per 10,000 residents`,
  UI_MAP_METRIC_HEL_CASTTHMA: `Adults with asthma, per 10,000 residents`,
  UI_MAP_METRIC_HEL_CHECKUP: `Number of routine doctor visits annually, per 10,000 residents`,
  UI_MAP_METRIC_HEL_FRUITSVEGGIES: `Average spending on fresh fruits and vegetables monthly`,
  UI_MAP_METRIC_HEL_LE: `Average life expectancy`,
  UI_MAP_METRIC_HEL_MHLTH: `Adults reporting poor mental health for 14 days or more, per 10,000 residents`,
  UI_MAP_METRIC_HEL_OBESITY: `Adult obesity, per 10,000 residents`,
  UI_MAP_METRIC_HEL_PERPRI: `Population with private health insurance (%)`,
  UI_MAP_METRIC_HEL_PERPUB: `Population with public health insurance (%)`,
  UI_MAP_METRIC_HEL_PHARMACAP: `Licensed pharmacies, per 10,000 residents`,
  UI_MAP_METRIC_HEL_PHLTH: `Adults with poor physical health, per 10,000 residents`,
  UI_MAP_METRIC_HEL_SLEEP: `Adults sleeping less than 7 hours per night, estimated per 10,000 population`,
  UI_MAP_METRIC_HEL_STROKE: `Adults who have had a stroke, estimated per 10,000 population`,
  UI_MAP_METRIC_HEL_CLINCAP: `Number of clinics, per 10,000 residents`,
  UI_MAP_METRIC_HEL_GROCCAP: `Number of grocery stores, per 10,000 residents`,
  UI_MAP_METRIC_HEL_PRTOPU: `Not available`,
  UI_MAP_METRIC_HEL_PERINS: `Population with health insurance (%)`,
  UI_MAP_METRIC_HEL_PERUNIN: `Percent of population that is uninsured`,
  UI_FEEDER_TITLE_FEEDER_CHART: `Dallas ISD School Feeder Patterns`,
  UI_FEEDER_TITLE_FEEDER_DESC: `<p>A feeder pattern is the sequence of schools a student attends as they advance from grade to grade, based on their residing address.</p><p>The first chart shows each feeder pattern’s average Community Resource Index on a scale from 0-100.</p><p>The second chart shows the distribution of campuses across the range of the Community Resource Index.</p><p>Hover and click within the charts to see more data about each feeder pattern and its schools. You can also use the search bar to find a school and highlight its feeder pattern and campus.</p>`,
  UI_FEEDER_TITLE_SCHOOLS_CHART: `Distribution of campuses across CRI`,
  UI_FEEDER_TOOLTIP_INDEX_DESC: `*Index scores measured 0 to 100`,
  UI_FEEDER_TOOLTIP_SUBINDEX_AVG: `Average {metric} index score:`,
  UI_FEEDER_FEEDER_CHART_DESC: `Bar chart indicating average CRI for each feeder in Dallas ISD.`,
  UI_FEEDER_SCHOOL_CHART_DESC: `Scatterplot chart indicating number of schools with a given CRI value. Schools are highlighted when their feeder is hovered in the feeder chart.`,
  UI_MAP_TOOLTIP_FEEDER: `{name} Schools`,
  TERM_INDEX_AVERAGE: `Index Average`,
  TERM_PLURAL: `{term}s`,
  UI_MAP_PANEL_SELECT: `Select filter category...`,
  UI_MAP_PANEL_SELECT_QUINTILE: `Select quintile`,
  UI_MAP_PANEL_QUINTILE_0: `First quintile`,
  UI_MAP_PANEL_QUINTILE_1: `Second quintile`,
  UI_MAP_PANEL_QUINTILE_2: `Third quintile`,
  UI_MAP_PANEL_QUINTILE_3: `Fourth quintile`,
  UI_MAP_PANEL_QUINTILE_4: `Fifth quintile`,
  UI_MAP_PANEL_QUINTILE_ENABLE: `Enable {quintile} quintile`,
  UI_MAP_PANEL_QUINTILE_DISABLE: `Disable {quintile} quintile`,
  UI_MAP_PANEL_METRIC_ENABLE: `Enable metric {metric}`,
  UI_MAP_PANEL_METRIC_DISABLE: `Disable metric {metric}`,
  FIRST: `first`,
  SECOND: `second`,
  THIRD: `third`,
  FOURTH: `fourth`,
  FIFTH: `fifth`,
  ALL_FIVE: `all five`,
  QUINTILE: `quintile`,
  QUINTILES: `quintiles`,
  NO: `no`,
  AND: `and`,
  UI_SD_B_AVG: `well below average`,
  UI_SD_SB_AVG: `slightly below average`,
  UI_SD_AVG: `average`,
  UI_SD_SA_AVG: `slightly above average`,
  UI_SD_A_AVG: `well above average`,
  UI_MAP_METRIC_DEM_TOTP: `Total residents`,
  UI_MAP_METRIC_DEM_POPWH: `White, Non-Hispanic population`,
  UI_MAP_METRIC_DEM_POPBL: `Black, Non-Hispanic population`,
  UI_MAP_METRIC_DEM_POPAS: `Asian, Non-Hispanic population`,
  UI_MAP_METRIC_DEM_POPHI: `Hispanic population`,
  UI_MAP_METRIC_DEM_POPM: `Male population`,
  UI_MAP_METRIC_DEM_POPF: `Female population`,
  UI_MAP_METRIC_DEM_THH: `Total households`,
  UI_MAP_METRIC_DEM_POPSE: `Senior population (65 and over)`,
  UI_MAP_METRIC_DEM_POPCH: `Children under 18`,
  // Added 2020-08-26
  UI_MAP_METRIC_DEM_PERWH: `Percent of White, Non-Hispanic Population in Buffer`,
  UI_MAP_METRIC_DEM_PERAS: `Percent of Asian, Non-Hispanic Population in Buffer`,
  UI_MAP_METRIC_DEM_PERBL: `Percent of Black, Non-Hispanic Population in Buffer`,
  UI_MAP_METRIC_DEM_PERCH: `Percent of Child Population (Under 18) in Buffer`,
  UI_MAP_METRIC_DEM_PERF: `Percent of Female Population, All Ages in Buffer`,
  UI_MAP_METRIC_DEM_PERHI: `Percent of Hispanic Population, All Races in Buffer`,
  UI_MAP_METRIC_DEM_PERM: `Percent of Male Population, All Ages in Buffer`,
  UI_MAP_METRIC_DEM_PERSE: `Percent of Senior Population (65 and Over) in Buffer`,
  // End added 2020-08-26
  // Below this line not in explorer.
  SCHOOL_PROSE_FEEDER_GENERIC: `<p>This school is part of a community with many strengths and assets that might not be fully captured by quantitative data in the Community Resource Explorer (CRE). The CRE highlights what resources and amenities might be available to residents based on publicly available datasets. These datasets, while helpful, do not include important aspects of a neighborhood, such as the history of how it came to be, cultures of the residents, and trusted institutions/points of pride, like places of worship and neighborhood associations. Over time, we hope to add these types of qualitative assets to the school community profiles. Please help us to do so by sharing insight you have about your neighborhood <a href="/contact/" target="_blank">here</a>.</p>`,
  SCHOOL_PROSE_FEEDER_LINCOLN: `<p>The Lincoln feeder pattern is located in South Dallas. The community is rich in history, tradition, and generational pride, exemplified by the second high school built for Black students in the late 1930s, Lincoln. South Dallas was home to some of the earliest Black professionals in Dallas, including Dr. Watts, Dr. Pinkston, and Pearl C. Anderson. Other points of pride and strength in the neighborhood are the churches, Black Greek Row, the South Dallas Cultural Center, Martin Luther King, Jr. Community Center, long-time childcare centers, and neighborhood associations, to name a few.</p><p><a href="/contact/" target="_blank" rel="noreferrer">Let us know more about your neighborhood.</a></p>`,
  SCHOOL_PROSE_FEEDER_SPRUCE: `<p>The Spruce feeder pattern is located in Pleasant Grove, southeast of Downtown Dallas. Pleasant Grove is a young community with an entrepreneurial spirit—Pleasant Grove is home to many small business owners and budding entrepreneurs. Churches are also important to the fabric of the community, as are the bazaars, local restaurants, and local institutions (like Eastfield College and the Southeast Dallas Chamber of Commerce). The Salvation Army on Elam, the branch libraries off Lake June Road, and the Great Trinity Forest are other local assets.</p><p><a href="/contact/" target="_blank" rel="noreferrer">Let us know more about your neighborhood.</a></p>`,
  SCHOOL_PROSE_CRI_SCORE: `Community Resource Index`,
  SCHOOL_PROSE_AVG: `The community around {schoolname} is {rank} among communities represented in the Resource Explorer. (School communities in the "well above average" category are the most asset-rich.) See below for a full breakdown of its resources by category and measure.`,
  SCHOOL_PROSE_QUINTILE_BELOW_FIFTH: `<p>{schoolname} is in the {quintile} quintile of schools in our Community Resource Index. (School communities in the 5th quintile are the most asset-rich.) See below for a full breakdown of its resources by category and measure.</p>`,
  SCHOOL_PROSE_QUINTILE_FIFTH: `<p>{schoolname} is in the fifth quintile of schools in our Community Resource Index. (School communities in this quintile are the most asset-rich.) See below for a full breakdown of its resources by category and measure.</p>`,
  SCHOOL_PROSE_TOP: `Based on how this school community compares to others, the data suggest that the following might be assets/strengths: {quintiles}.`,
  SCHOOL_PROSE_BOTTOM: `Based on how this school community compares to others, the data suggest that the following might be needs/areas for additional resources: {quintiles}.`,
  SCHOOL_BUTTON_PRINT: `Print PDF`,
  SCHOOL_PROSE_RACE: `The racial makeup of this community is {black}% Black, {hispanic}% Hispanic, {asian}% Asian, and {white}% white.`,
  SCHOOL_PROSE_DEMO_LABEL: `Residents in this campus community`,
  SCHOOL_MAP_DESCRIPTOR: `The data on this page is representative of the 2-mile radius around the
  school, as shown in the map above.`,
  SCHOOL_SCALE_WBA: `well below average`,
  SCHOOL_SCALE_BA: `slightly below average`,
  SCHOOL_SCALE_A: `average`,
  SCHOOL_SCALE_AA: `slightly above average`,
  SCHOOL_SCALE_WAA: `well above average`,
  SCHOOL_SCALE_MEAN: `Mean`,
  SCHOOL_SCALE_COMM: `This community`,
  SCHOOL_SCALE_COMM_REL: `This community relative to average`,
  SCHOOL_SCALE_MIN: `Min`,
  SCHOOL_SCALE_MAX: `Max`,
  SCHOOL_CRI_DESCRIPTOR: `The Community Resource Index is an average of all Index categories (economics, education, family, health, and community). It presents an overall view of the resources available to each school’s surrounding community.`,
}
export default en_US
